import { ampli, ScreenViewedProperties } from '../../core/ampli';

export enum AMPLITUDE_SCREEN_NAMES {
  'login' = 'Registrant Sign In',
  'newUser' = 'Registrant New User',
  'password' = 'Registrant Password',
  'reset' = 'Registrant Password Reset',
  'overview' = 'Registrant Summary',
  'selectParticipant' = 'Registrant Select Member',
  'selectGroup' = 'Registrant Select Option',
  'answerQuestions' = 'Registrant Answer Questions',
  'cart' = 'Registrant Cart',
  'agreements' = 'Registrant Waiver',
  'verifyMembership_usa_lacrosse' = 'Registrant Verify USA Lacrosse',
  'verifyMembership_usa_hockey' = 'Registrant Verify USA Hockey',
  'verifyMembership_hockey_canada' = 'Registrant Verify Hockey Canada',
}

interface AmplitudeTrackActions {
  screenViewed: (
    path: string | undefined,
    isUserLoggedIn: boolean,
    userLoginEmail: string | undefined,
    formId: string | undefined,
    ngbIntegrationCode?: string | undefined
  ) => void;
}

// Define the amplitude functions available for use through the hook
export const useAmplitude = () => {
  // Exposing method to identify user in Amplitude
  const amplitudeIdentify = (uuid: string, email: string) => {
    ampli.identify(uuid, {
      email: email,
    });
  };

  // Exposing methods to track events in Amplitude
  const amplitudeTrack: AmplitudeTrackActions = {
    // Tracks which page pathname the user is viewing
    screenViewed: (
      path: string | undefined,
      isUserLoggedIn: boolean,
      userLoginEmail: string | undefined,
      formId: string | undefined,
      ngbIntegrationCode?: string | undefined
    ) => {
      // we dont want to track sreen name for PaymentPage
      if (path === 'payment') {
        return;
      }

      const pathKey: keyof typeof AMPLITUDE_SCREEN_NAMES = getPathKey(
        path,
        isUserLoggedIn,
        userLoginEmail,
        ngbIntegrationCode
      );

      if (AMPLITUDE_SCREEN_NAMES[pathKey] && formId) {
        ampli.screenViewed({
          screen_name: AMPLITUDE_SCREEN_NAMES[
            pathKey
          ] as ScreenViewedProperties['screen_name'],
          url: `/form/${formId}/${path ? path : ''}`,
        });
      }
    },
  };

  return { amplitudeIdentify, amplitudeTrack };
};

const getPathKey = (
  path: string | undefined,
  isLoggedIn: boolean,
  email: string | undefined,
  ngbIntegrationCode: string | undefined
): keyof typeof AMPLITUDE_SCREEN_NAMES => {
  if (path === 'verifyMembership' && ngbIntegrationCode) {
    return `verifyMembership_${ngbIntegrationCode}` as keyof typeof AMPLITUDE_SCREEN_NAMES;
  }

  if (isLoggedIn) {
    return path &&
      [
        'selectParticipant',
        'selectGroup',
        'cart',
        'agreements',
        'answerQuestions',
      ].includes(path)
      ? (path as keyof typeof AMPLITUDE_SCREEN_NAMES)
      : 'overview';
  } else {
    switch (path) {
      case 'login':
        return email ? 'password' : 'login';
      case 'newUser':
        return email ? 'newUser' : 'login';
      case 'reset':
        return 'reset';
      default:
        return 'login';
    }
  }
};
