import { Button, Icon, Modal } from '@teamsnap/teamsnap-ui';
import React from 'react';

// Local Imports
import { PaddedContentArea } from 'components/shared/PaddedContentArea/PaddedContentArea';
import { ActionContainer } from '../../shared';
// import '../ManageHouseholdMember/ManageHouseholdMember.scss';
import { Banner } from 'components/shared/Banner';
import { CartButton } from 'components/shared/CartButton';
import {
  FormsService,
  Registration,
  RegistrationForm,
  RegistrationUpdate,
  RegistrationWaiver,
  RegistrationWaiverCreate,
  UserInfo,
} from 'core/api';
import { useFormSelector } from 'state/form/formSlice';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import {
  createWaiverResult,
  useRegistrationSelector,
} from 'state/registration/registrationSlice';
import { useUserSelector } from 'state/user/userSlice';
import '../../../pages/Form/selectParticipant/_sections/ManageHouseholdMember/ManageHouseholdMember.scss';
import '../GroupCard/GroupCard.scss';
import { Header } from '../Header/Header';
import { RadioGroup } from '../RadioGroup/RadioGroup';

import {
  Checkbox,
  Input,
  InputWrapper,
  Icon as SnapUIIcon,
} from '@teamsnap/snap-ui';
import { CartWarningModal } from 'components/CartWarningModal/CartWarningModal';
import ENVIRONMENT from 'core/environment';
import { FeatureFlagConfig, FeatureFlags } from 'core/feature-flags';
import { FeatureFlagService } from 'frontend-toolkit';
import {
  useCartHasGroupsWithCapacityLimitSelector,
  useRegistrationsAllWaitlistedSelector,
  useRegistrationsAtCapacitySelector,
  useRegistrationsFromInactiveParticipantGroupsSelector,
} from '../../../state/registration/registrationSelectors';

import './SignWaivers.scss';
import hockeyCanadaLogoImage from 'assets/images/hockey-canada-logo.svg';
import { NGB_INTEGRATION_CODES } from 'core/constants';

export type WaiverData = RegistrationForm['waivers'][0] & {
  selected?: boolean;
};

export interface Props {
  formWaivers: WaiverData[];
  registrationWaivers?: RegistrationWaiver[];
  registrationForm: RegistrationForm;
}

const getInitials = (name: string) => {
  return name
    .split(' ')
    .map((part) => part[0])
    .join('')
    .toUpperCase();
};

const isInitialsValid = (initials: string, user?: UserInfo | null) => {
  const userFullName = `${user?.firstName?.trim()} ${user?.lastName?.trim()}`
    .replace(/['.-]/g, '')
    .toUpperCase();
  return initials.toUpperCase() === getInitials(userFullName);
};

const isFullNameValid = (fullName: string, user?: UserInfo | null) => {
  const userFullName = `${user?.firstName?.trim()} ${user?.lastName?.trim()}`;
  return userFullName.toUpperCase() === fullName.toUpperCase().trim();
};

const isImage = (waiverFileName: string) =>
  waiverFileName.includes('.png') ||
  waiverFileName.includes('.jpg') ||
  waiverFileName.includes('.jpeg') ||
  // This is in place due to bug where file extension was not used on upload
  waiverFileName.includes('image/png') ||
  waiverFileName.includes('image/jpg') ||
  waiverFileName.includes('image/jpeg');

const getFileType = (waiverFileName: string) => {
  if (
    waiverFileName.includes('.png') ||
    waiverFileName.includes('.jpg') ||
    waiverFileName.includes('.jpeg') ||
    waiverFileName.includes('image/png') ||
    waiverFileName.includes('image/jpg') ||
    waiverFileName.includes('image/jpeg')
  ) {
    return 'image';
  } else if (
    waiverFileName.includes('.pdf') ||
    waiverFileName.includes('application/pdf')
  ) {
    return 'pdf';
  } else {
    return 'doc';
  }
};

const getIconName = (waiverFileName: string) => {
  if (
    waiverFileName.includes('.pdf') ||
    waiverFileName.includes('application/pdf')
  ) {
    return 'picture_as_pdf';
  } else {
    return 'description';
  }
};

const WaiverFile = ({
  regId,
  waiverFileName,
  waiverId,
  waiverOriginalFileName,
}: {
  regId: number;
  waiverFileName: string;
  waiverId: number;
  waiverOriginalFileName?: string;
}) => {
  const [url, setUrl] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [downloadCalled, setDownloadCalled] = React.useState(false);

  React.useEffect(() => {
    FormsService.geFormWaiverDownloadUrl(regId, waiverId)
      .then((x) => setUrl(x.url))
      .catch(() => setHasError(true))
      .finally(() => setDownloadCalled(true));
  }, []);

  return (
    <>
      {downloadCalled && (
        <>
          {isImage(waiverFileName) ? (
            <>
              {!hasError ? (
                <a
                  rel="noreferrer"
                  data-testid={`WaiverFile--download-${getFileType(
                    waiverFileName
                  )}-link`}
                  className="WaiverFile--download-link"
                  target="_blank"
                  href={url}
                  download={waiverFileName}
                >
                  <img
                    data-testid="WaiverFile--image"
                    className="WaiverFile--image sui-w-full sui-p-2 mx-auto"
                    alt=""
                    src={url}
                  />
                </a>
              ) : (
                <div>
                  <figure className="sui-flex sui-flex-col sui-items-center">
                    <img
                      data-testid="WaiverFile--image"
                      className="WaiverFile--image--error WaiverFile--image mx-auto"
                      alt={waiverOriginalFileName}
                      src={url}
                    />
                    <figcaption className="sui-body sui-text-neutral-text-medium sui-mt-1 sui-text-center">
                      {waiverOriginalFileName}
                    </figcaption>
                  </figure>
                  <p className="sui-mb-4 sui-caption sui-text-negative-text sui-text-center sui-mt-2 sui-flex sui-items-center sui-justify-center">
                    <SnapUIIcon name="report" size="s" /> Failed to retrieve
                    image. Cancel and reopen this digital agreement.
                  </p>
                </div>
              )}
            </>
          ) : (
            <>
              {!hasError ? (
                <>
                  <a
                    rel="noreferrer"
                    data-testid={`WaiverFile--download-${getFileType(
                      waiverFileName
                    )}-link`}
                    className="WaiverFile--download-link sui-inline-flex sui-items-center sui-mb-1 sui-text-action-text"
                    target="_blank"
                    href={url}
                  >
                    <SnapUIIcon
                      name={getIconName(waiverFileName)}
                      className="sui-mr-1"
                    />
                    {waiverOriginalFileName
                      ? waiverOriginalFileName
                      : `Download ${getFileType(waiverFileName)}`}
                  </a>
                  <p className="sui-body sui-mb-4 sui-caption">
                    Click to view {getFileType(waiverFileName)} file
                  </p>
                </>
              ) : (
                <>
                  <div
                    data-testid={`WaiverFile--download-${getFileType(
                      waiverFileName
                    )}-link`}
                    className="sui-inline-flex sui-items-center sui-mb-1 sui-text-neutral-text-disabled"
                  >
                    <SnapUIIcon
                      name={getIconName(waiverFileName)}
                      className="sui-mr-1"
                    />
                    {waiverOriginalFileName
                      ? waiverOriginalFileName
                      : `Download ${getFileType(waiverFileName)}`}
                  </div>
                  <p className="sui-body sui-mb-3 sui-caption sui-text-negative-text">
                    Failed to retrieve file. Cancel and reopen this digital
                    agreement.
                  </p>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export const SignWaivers = ({ formWaivers, registrationWaivers, registrationForm }: Props) => {
  const [showCartWarningModal, setShowCartWarningModal] = React.useState(false);
  const [waiversList, setWaiversList] = React.useState<WaiverData[]>([]);
  const [waiverToSign, setWaiverToSign] = React.useState<WaiverData>();
  const [showError, setShowError] = React.useState(false);
  const [agreement, toggleAgreement] = React.useState(false);
  const [initials, setInitials] = React.useState('');
  const [initialsError, setInitialsError] = React.useState('');
  const [fullName, setFullName] = React.useState('');
  const [fullNameError, setFullNameError] = React.useState('');
  const [isDeclined, setIsDeclined] = React.useState<boolean | null>(null);

  const navigate = useAppNavigate();
  const dispatch = useAppDispatch();

  const user = useUserSelector();
  const form = useFormSelector();
  const registrationData = useRegistrationSelector();

  const cartHasGroupsWithCapacityLimit =
    useCartHasGroupsWithCapacityLimitSelector();
  const registrationsAtCapacity = useRegistrationsAtCapacitySelector();
  const allInCartRegistrationsWaitlisted =
    useRegistrationsAllWaitlistedSelector();
  const registrationsWithInactiveGroup =
    useRegistrationsFromInactiveParticipantGroupsSelector();

  const hasHockeyCanadaIntegration = registrationForm?.ngbIntegrations?.find(
    (integration) => integration.ngbIntegration.code === NGB_INTEGRATION_CODES.hockey_canada
  );

  React.useEffect(() => {
    if (
      registrationsAtCapacity.atCapacityWithoutWaitlist.length > 0 ||
      registrationsAtCapacity.atCapacityWithWaitlistAndModalInfoNeeded.length >
      0 ||
      registrationsWithInactiveGroup.length > 0
    ) {
      setShowCartWarningModal(true);
    }
  }, [
    registrationsAtCapacity?.atCapacityWithoutWaitlist?.length,
    registrationsAtCapacity?.atCapacityWithWaitlistAndModalInfoNeeded?.length,
    registrationsWithInactiveGroup?.length,
  ]);

  const { organizationId } = form || {};

  // TODO: Remove Feature Flag once feature is fully released.
  const capacityLimitsAreEnabled = FeatureFlagService.isFeatureEnabledForOrg(
    FeatureFlagConfig[FeatureFlags.PARTICIPANT_GROUP_CAPACITY_LIMIT],
    ENVIRONMENT,
    organizationId
  );

  const saveWaiverSignatureForAllRegistrations = () => {
    if (
      waiverToSign?.signatureType === 'initials' &&
      !isInitialsValid(initials, user)
    ) {
      setInitialsError(
        'Entered initials do not match your account information.'
      );
      return;
    }

    if (
      waiverToSign?.signatureType === 'full_name' &&
      !isFullNameValid(fullName, user)
    ) {
      setFullNameError('Entered name does not match your account information.');
      return;
    }

    registrationData?.registrations
      ?.filter(
        (reg: Registration) =>
          reg.formId === form?.id &&
          reg.status === RegistrationUpdate.status.IN_CART
      )
      ?.forEach((formResult: Registration) => {
        // only create form waiver result if there is no waiver result for the selected waiver and form result
        if (
          !registrationData?.waiverResults?.find(
            (wr: RegistrationWaiver) =>
              wr.registrationId === formResult.id &&
              wr.formWaiverId === waiverToSign?.id
          )
        ) {
          let signature;
          if (waiverToSign?.signatureType === 'initials') {
            signature = initials;
          } else if (waiverToSign?.signatureType === 'full_name') {
            signature = fullName.trim();
          }

          const data = {
            registrationId: formResult.id,
            body: {
              formWaiverId: waiverToSign?.id,
              ...(signature && { signature }),
              declined: isDeclined ?? false,
            } as RegistrationWaiverCreate,
          };

          dispatch(createWaiverResult(data)).unwrap();
        }
      });

    setWaiverToSign(undefined);
  };

  const findSignedWaiver = (waiver: WaiverData) =>
    registrationData?.registrations
      ?.filter(
        (reg: Registration) =>
          reg.formId === form?.id &&
          reg.status === RegistrationUpdate.status.IN_CART
      )
      .every((reg) =>
        registrationWaivers?.find(
          (regWaiver) =>
            regWaiver.registrationId === reg.id &&
            waiver.id === regWaiver.formWaiverId
        )
      );

  const goToNextPage = () => {
    if (waiversList.filter((w: WaiverData) => !w.selected).length === 0) {
      navigate(`/form/${form?.id}/payment`);
    } else {
      setShowError(true);
    }
  };

  React.useEffect(() => {
    const newList: WaiverData[] = [];
    formWaivers?.forEach((waiver) => {
      if (findSignedWaiver(waiver)) {
        newList.push({ ...waiver, selected: true });
      } else {
        newList.push({ ...waiver, selected: false });
      }
    });
    setWaiversList(newList);
  }, [registrationWaivers]);

  const disableSignatureButton =
    (waiverToSign?.canBeDeclined && isDeclined === null) ||
    !waiverToSign?.canBeDeclined &&
    ((waiverToSign?.signatureType === 'checkbox_accept' && !agreement) ||
      (waiverToSign?.signatureType === 'initials' && !initials) ||
      (waiverToSign?.signatureType === 'full_name' && !fullName))

  const openSignModal = (waiver: WaiverData) => {
    toggleAgreement(false);
    setInitials('');
    setFullName('');
    setInitialsError('');
    setFullNameError('');
    setWaiverToSign(waiver);
  };

  return (
    <>
      <span className="modal-wrapper">
        <Modal
          heading={''}
          showClose={false}
          show={!!waiverToSign}
          allowOverlayClose={false}
        >
          <ActionContainer
            submitting={false}
            header={
              <Header
                title={waiverToSign?.title || ''}
                icon={
                  hasHockeyCanadaIntegration && waiverToSign?.externalId ? (
                    <img src={hockeyCanadaLogoImage} alt="HCR" />
                  ) : null
                }
                textAlign="left"
                truncateText={false}
                rightIcon={
                  <Button
                    iconPosition="left"
                    mods="close-button sui-m-0 sui-w-auto sui-text-gray-10 sui-min-w-auto"
                    icon="dismiss"
                    type="link"
                    onClick={() => setWaiverToSign(undefined)}
                  />
                }
                testId="waiver-modal-header"
              />
            }
            footer={
              <div className="sui-flex items-center sui-justify-end">
                <Button
                  key="agree"
                  isDisabled={disableSignatureButton}
                  color="primary"
                  mods="sui-w-auto sui-my-1 t:sui-w-auto sui-px-5 sui-py-1 sui-h-auto sui-leading-1"
                  onClick={saveWaiverSignatureForAllRegistrations}
                >
                  <span data-testid="waiver-modal-agree">Submit {waiverToSign?.canBeDeclined ? 'response' : 'signature'}</span>
                </Button>
              </div>
            }
            children={
              <>
                {waiverToSign && (
                  <>
                    <div
                      className="waiver-content"
                      dangerouslySetInnerHTML={{ __html: waiverToSign.content }}
                    ></div>
                    <br />
                    {waiverToSign.filename && (
                      <WaiverFile
                        regId={form?.id || 0}
                        waiverFileName={waiverToSign.filename}
                        waiverOriginalFileName={waiverToSign.originalFileName}
                        waiverId={waiverToSign.id}
                      />
                    )}
                    {
                      waiverToSign.canBeDeclined ? (
                        <RadioGroup
                          name='waiver-agreement'
                          onRadioSelect={(selected) => selected !== null && setIsDeclined(selected?.value === 'disagree')}
                          value={isDeclined === null ? undefined : (isDeclined ? 'disagree' : 'agree')}
                          options={
                            [
                              {
                                label: 'Agree',
                                value: 'agree',
                              },
                              {
                                label: 'Decline',
                                value: 'disagree',
                              }
                            ]
                          }
                        />
                      ) : (
                        <>
                          {waiverToSign?.signatureType === 'full_name' && (
                            <InputWrapper
                              required
                              label="Full Name"
                              key={'full-name-input'}
                              name="full-name"
                              helpText="Enter your full name to acknowledge that you have read and accept the information above."
                            >
                              <Input
                                errors={fullNameError ? [fullNameError] : []}
                                name="full-name"
                                type="text"
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                              />
                            </InputWrapper>
                          )}

                          {waiverToSign?.signatureType === 'initials' && (
                            <InputWrapper
                              required
                              label="Initials"
                              key={'initials-input'}
                              name="initials"
                              helpText="Enter your initials to acknowledge that you have read and accept the information above."
                            >
                              <Input
                                errors={
                                  initialsError
                                    ? [
                                      initialsError,
                                      'Initials should match your first and last name. Enter an initial for every unique name separated by a space. For example, if your first name is Jimmy John and your last name is Jones-Smith, your initials are JJJ.',
                                    ]
                                    : []
                                }
                                name="initials"
                                type="text"
                                value={initials}
                                onChange={(e) => setInitials(e.target.value)}
                              />
                            </InputWrapper>
                          )}

                          {waiverToSign?.signatureType === 'checkbox_accept' && (
                            <Checkbox
                              label="I agree."
                              name="agreement checkbox"
                              extraProps={{
                                input: {
                                  'data-testid': 'checkbox_accept',
                                  checked: agreement,
                                  onChange: () => toggleAgreement(!agreement),
                                },
                              }}
                            />
                          )}
                        </>
                      )
                    }
                  </>
                )}
              </>
            }
          />
        </Modal>
      </span>
      <ActionContainer
        submitting={false}
        header={
          <Header
            title="Checkout - Agreements"
            navigation={
              <Button
                iconPosition="left"
                mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                icon="arrow-left"
                type="link"
                size="large"
                onClick={() => {
                  navigate(`/form/${form?.id}/cart`);
                }}
              />
            }
            rightIcon={<CartButton />}
            profileName={`${user?.firstName} ${user?.lastName}`}
            testId="waiver-page-header"
          />
        }
        footer={
          <div className="t:sui-flex t:items-center t:sui-justify-between">
            <div>
              <Button
                mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
                onClick={() => {
                  navigate(`/form/${form?.id}/cart`);
                }}
                icon="arrow-left"
                iconPosition="left"
              >
                Back
              </Button>
            </div>

            <Button
              key="check-out"
              color="primary"
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
              icon="arrow-right"
              iconPosition="right"
              onClick={goToNextPage}
            >
              Next
            </Button>
          </div>
        }
        children={
          <div>
            {cartHasGroupsWithCapacityLimit &&
              capacityLimitsAreEnabled &&
              !allInCartRegistrationsWaitlisted && (
                <Banner
                  rounded={true}
                  type="warning"
                  classes="u-padMd sui-mb-2"
                  children="Payment required to secure reserved spots."
                />
              )}
            <h3 data-testid="waiver-page-header">Waivers</h3>
            <p className="u-colorNeutral8">
              You only need to sign once and it covers all participants you are
              registering for.
            </p>
            <div className="u-padTopLg" data-testid="waivers-list-select">
              {waiversList.map((waiver: WaiverData, index) => (
                <PaddedContentArea
                  key={`waiver--${index}`}
                  rounded
                  classes="u-flexCol GroupCard"
                  bgColor={waiver.selected ? 'u-bgPrimary1' : ''}
                  borderColor={waiver.selected ? 'u-borderColorPrimary' : ''}
                  error={showError && !waiver.selected}
                  errorMessage={
                    showError && !waiver.selected
                      ? 'Please review and sign.'
                      : ''
                  }
                  onClick={() => openSignModal(waiver)}
                >
                  <div
                    data-testid={`waiver-card--${index}`}
                    className="u-flex u-flexJustifyBetween u-fontSizeLg u-colorNeutral9 u-flexAlignItemsCenter"
                  >
                    <div className="sui-flex sui-gap-2 u-fontSizeLg u-colorNeutral9 sui-items-center sui-w-3/4">
                      {
                        hasHockeyCanadaIntegration && waiver.externalId && (
                          <img className="sui-mb-1" src={hockeyCanadaLogoImage} alt="HCR" />
                        )
                      }
                      {waiver.selected && (
                        <div>
                          <Icon
                            name="task-alt"
                            style={{
                              top: 0,
                              fontSize: '15px',
                              color: '#199a50',
                              marginTop: '4px',
                              marginRight: '5px',
                            }}
                            mods="waiver-checked-icon"
                          />
                        </div>
                      )}
                      <div data-testid={`waiver-title--${index}`}>
                        {waiver.title}
                      </div>
                    </div>
                    <Button type="link">
                      <span data-testid={`waiver-card-button-sign--${index}`}>
                        {waiver.selected ? 'Completed' : 'Sign'}
                      </span>
                    </Button>
                  </div>
                </PaddedContentArea>
              ))}
            </div>
          </div>
        }
      />
      {capacityLimitsAreEnabled && (
        <CartWarningModal
          registrationsAtCapacity={registrationsAtCapacity}
          registrationsWithInactiveGroup={registrationsWithInactiveGroup}
          isOpen={showCartWarningModal}
          onClose={() => {
            setShowCartWarningModal(false);
            navigate(`/form/${form?.id}/cart?warning=false`);
          }}
        />
      )}
    </>
  );
};
