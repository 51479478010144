import * as React from 'react';
import { useFormikContext } from 'formik';
import { ValidationError } from 'yup';

// Local imports
import { FormAdjustment, FormField as FormFieldModel } from 'core/api';
import { isVisible, toShortDollar, Values } from 'core/utilities';
import { getValidation } from 'core/validations';
import { FieldType, FormField, FormFieldType } from '../FormField/FormField';
import { AdjustmentValueCollector } from '../AdjustmentValue/AdjustmentValueCollector';
import { NgbFieldData } from 'pages/Form/VerifyMembershipPage';

interface Props {
  field: FormFieldModel;
  editableNgbFields: NgbFieldData[];
}

export const Question = ({ field, editableNgbFields, ...props }: Props) => {
  const values = useFormikContext().values as Values;

  if (!isVisible(field.visibilityRules, values as Values)) {
    return null;
  }

  const value = values[field.id];

  const createCollector = (v: string | string[], optionValue?: string) => (
    <AdjustmentValueCollector
      fieldValue={
        Array.isArray(value)
          ? value?.find((v: unknown) => v === optionValue) || ''
          : (v as string)
      }
      adjustments={(field.adjustments as FormAdjustment[]) || []}
    />
  );

  return (
    <FormField
      disabled={isFieldDisabled(field)}
      placeholder={getFieldPlaceholder(field)}
      type={getFieldType(field, editableNgbFields)}
      label={editableNgbFields.find((f) => f.name === field.formFieldDefinition.name)?.registrant_display || field.label || field.formFieldDefinition.label}
      name={String(field.id)}
      multiselect={field.multiselect}
      adjustmentAddon={createCollector}
      required={field.required}
      helpText={field.helpText || undefined}
      options={field.formFieldDefinition.externalType === 'ngb_integration' ? // sorting the options just for ngb fields
        field.options?.map((option: string) =>
          createQuestionOption(option, field.adjustments as FormAdjustment[])
        ).sort((a, b) => a.label.localeCompare(b.label)) :
          field.options?.map((option: string) =>
            createQuestionOption(option, field.adjustments as FormAdjustment[])
          )}
      validate={async (v) => {
        try {
          const validationVal = Array.isArray(v) && v.length === 0 ? null : v;

          await getValidation(field).validate(validationVal);
        } catch (e: unknown) {
          return (e as ValidationError).errors.join(' ');
        }
      }}
      {...props}
    />
  );
};

const isFieldDisabled = (field: FormFieldModel) => {
  return (
    (field.formFieldDefinition.name === FieldType.GENDER ||
      field.formFieldDefinition.name === FieldType.BIRTHDATE ||
      field.formFieldDefinition.name === FieldType.FIRST_NAME ||
      field.formFieldDefinition.name === FieldType.LAST_NAME) &&
    field.target === 'participant'
  );
};

function getFieldPlaceholder(field: FormFieldModel) {
  if (field.formFieldDefinition.name === FieldType.EMAIL) {
    return 'email@email.com';
  }

  return field.label;
}

function getFieldType(field: FormFieldModel, editableNgbFields: NgbFieldData[]) {
  if (field.type === 'date') {
    return FormFieldType.DATETIME;
  }

  // should hide internal fields or ngb fields that are not editable
  if (
    field.internal ||
    ((field.formFieldDefinition.externalId ||
      field.formFieldDefinition.externalType) && !editableNgbFields.map((f) => f.name).includes(field.formFieldDefinition.name))
  ) {
    return FormFieldType.HIDDEN;
  }

  return field.type as FormFieldType;
}

function createQuestionOption(option: string, adjustments: FormAdjustment[]) {
  const adjustment = adjustments.find((a) => a.answerToMatch === option);

  let label = option;

  if (adjustment && adjustment.amount !== null) {
    label += ` - ${adjustment.definition.name} (${Math.sign(adjustment.amount) === -1 ? '-' : ''
      }$${toShortDollar(Math.abs(adjustment.amount))}`;
    if (adjustment.maxAmount) {
      label += ` to $${toShortDollar(adjustment.maxAmount)}`;
    }
    label += ')';
  }

  return { value: option, label };
}
