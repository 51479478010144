/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type FormWaiver = {
    id: number;
    title: string;
    content: string;
    signatureType: FormWaiver.signatureType;
    active: boolean;
    filename: string;
    originalFileName: string;
    canBeDeclined: boolean;
    externalId: string | null;
};

export namespace FormWaiver {

    export enum signatureType {
        CHECKBOX_ACCEPT = 'checkbox_accept',
        INITIALS = 'initials',
        FULL_NAME = 'full_name',
        E_SIGNATURE = 'e-signature',
    }


}

