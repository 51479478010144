import * as React from 'react';
import { FieldGroup, FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';
import { Field, FieldValidator, useField } from 'formik';
import { DateTimePicker, PasswordToggleInput } from 'frontend-toolkit';

import { RadioGroup } from '../RadioGroup/RadioGroup';
import { FileUpload } from '../FileUpload/FileUpload';
import { Icon, Tooltip, TooltipProvider } from '@teamsnap/snap-ui';

export enum FieldType {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  BIRTHDATE = 'birthdate',
  GENDER = 'gender',
  RELATION = 'relation',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  PHONE = 'phone',
  FILE = 'file',
}

export enum FormFieldType {
  CHECKBOX = 'checkbox',
  DATETIME = 'datetime',
  EMAIL = 'email',
  PASSWORD_INPUT = 'input',
  PASSWORD = 'password',
  SELECT = 'select',
  TEXT = 'text',
  RADIO = 'radio',
  TEXTAREA = 'textarea',
  FILE = 'file',
  HIDDEN = 'hidden',
}

type SelectOptions = {
  label: string;
  value: string;
};

type RadioOptions = {
  label: string;
  value: string;
};

export interface Props {
  label?: string | React.ReactNode;
  mods?: string;
  name: string;
  options?: SelectOptions[] | RadioOptions[];
  placeholder?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  multiselect?: boolean;
  adjustmentAddon?: (v: string | string[], optionValue?: string) => JSX.Element;
  required?: boolean;
  validate?: FieldValidator;
  testId?: string;
  helpText?: string;
  type:
    | FormFieldType.CHECKBOX
    | FormFieldType.DATETIME
    | FormFieldType.EMAIL
    | FormFieldType.PASSWORD_INPUT
    | FormFieldType.PASSWORD
    | FormFieldType.TEXT
    | FormFieldType.RADIO
    | FormFieldType.SELECT
    | FormFieldType.TEXTAREA
    | FormFieldType.FILE
    | FormFieldType.HIDDEN;
}

export const FormField = ({ label, adjustmentAddon, helpText, testId, ...props }: Props) => {
  const [field, meta, helpers] = useField(props);
  const { value } = field;
  const { error, touched } = meta;
  const { setTouched, setValue } = helpers;
  const { mods, name, options, type, disabled, required } = props;

  const characterLimit = 255
  const overCharLimit = type === FormFieldType.TEXTAREA && value != null && value.length > characterLimit
  
  const hasError = touched && (error || overCharLimit);
  const classes = ['Input', hasError ? 'sui-border-red-4' : '', mods].join(' ').trim();

  const generateLabel = () => {
    return (
      <>
        {label}
        {required && <span className="sui-text-red-4">*</span>}
        {helpText &&
          <TooltipProvider>
            <Tooltip
              align="center"
              content={helpText}
              side="right"
            >
              <Icon
                name="info"
                size="s"
                className="sui-cursor-pointer u-spaceLeftXs"
              />
            </Tooltip>
          </TooltipProvider>
        }
      </>
    );
  };

  // Doing this to avoid a bunch of warning being printed out
  const filteredField = { ...field, validate: undefined, checked: value?.toString() === 'true' ? true : false  }; // form field result values are stored in string
  const filteredProps = { ...props, validate: undefined, multiselect: undefined };

  const textAreaClasses = [
    'sui-form-control',
    'sui-border-solid',
    'sui-block',
    'sui-p-1',
    'sui-w-full',
    'sui-text-sm',
    'sui-rounded',
    'sui-text-gray-9',
    hasError ? 'sui-border-red-4' : 'sui-border-gray-5',
  ];

  const isMultiSelect = !!props?.multiselect;

  const renderSelectTestId = (label: string | React.ReactNode) => {
    if (typeof label === 'string') {
      return `${label}-selectbox`;
    }

    return label;
  };

  const renderOptionType = (multiselect: boolean) => {
    if (multiselect) {
      return options?.map((option) => (
        <div
          key={option.value}
          id={name}
          className="Checkbox"
          style={{ paddingBottom: 10 }} // override Checkbox bottom padding
        >
          <Field
            data-testid={renderSelectTestId(label)}
            className="Checkbox-input"
            type="checkbox"
            name={name.toString()}
            value={option.value}
          />

          <label className="Checkbox-label sui-body" htmlFor={name.toString()}>
            {option.label}
          </label>
          {adjustmentAddon && <div style={{ width: 'fit-content' }}>{adjustmentAddon(value, option.value)}</div>}
        </div>
      ));
    }

    return (
      <select
        className={`SelectBox-options sui-font-size-5 ${classes}`}
        data-testid={renderSelectTestId(label)}
        style={{ height: 48 }}
        id={name}
        {...filteredField}
        disabled={disabled}
      >
        <option value="">Select</option>
        {options?.map((option) => (
          <option key={option.value} label={option.label} {...filteredField} {...filteredProps} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  };

  const renderType = () => {
    let theDate;

    if (FormFieldType.DATETIME) {
      theDate = new Date(value);
      theDate = new Date(theDate.getUTCFullYear(), theDate.getUTCMonth(), theDate.getUTCDate());
    }

    switch (type) {
      case FormFieldType.CHECKBOX:
        return (
          <div className="Checkbox Checkbox--inline">
            <input data-testid={testId} className="Checkbox-input" id={name} {...filteredField} {...filteredProps} />
            <label className="Checkbox-label sui-body sui-flex" htmlFor={name}>
              {generateLabel()}
            </label>
          </div>
        );

      case FormFieldType.SELECT:
        return (
          <div className="sui-mb-2">
            <div
              className={
                !props?.multiselect && adjustmentAddon ? 'sui-flex select-container sui-items-start' : 'sui-flex'
              }
            >
              <div className={!props?.multiselect ? 'SelectBox ' : ''}>
                <label htmlFor={name} className="sui-label">
                  {generateLabel()}
                </label>

                {renderOptionType(isMultiSelect)}
              </div>

              {!props?.multiselect && adjustmentAddon && adjustmentAddon(value)}
            </div>
            {hasError && (
              <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
                {error}
              </FieldMessage>
            )}
          </div>
        );

      case FormFieldType.RADIO:
        return <RadioGroup label={''} name={name} options={options} />;

      case FormFieldType.DATETIME:
        return (
          <div className="sui-pb-0.5">
            <label htmlFor={name} className="sui-label">
              {generateLabel()}
            </label>

            <div>
              <DateTimePicker
                id={name}
                datetime={value ? theDate : null}
                dateFormat="P"
                inputClasses=""
                placeholderText="Select Date"
                onChange={(datetime) => {
                  if (!datetime) {
                    setValue('');
                  } else {
                    setValue(datetime.toISOString());
                  }
                }}
                onBlur={() => {
                  if (typeof value === 'undefined') {
                    setValue('');
                  }

                  setTouched(true);
                }}
                disabled={disabled}
                containerClasses={`sui-px-1 ${hasError ? 'sui-border-red-4' : ''}`}
              />
            </div>
          </div>
        );

      case FormFieldType.PASSWORD_INPUT:
        return (
          <>
            <label htmlFor={name} className="sui-label">
              {generateLabel()}
            </label>
            <PasswordToggleInput hasError={hasError} {...filteredField} {...filteredProps} />
          </>
        );

      case FormFieldType.TEXTAREA:
        return (
          <div className="sui-pb-0.5">
            <label htmlFor={name} className="sui-label">
              {generateLabel()}
            </label>

            <div
              className="sui-block"
              style={{
                height: 120,
                marginBottom: 10, // Needed to add margin for error messages below text area
              }}
            >
              <textarea
                autoComplete="off"
                data-testid={testId}
                {...filteredField}
                {...filteredProps}
                name={name}
                className={textAreaClasses.join(' ')}
                style={{
                  height: 120,
                  resize: 'none',
                  fontSize: '1rem',
                  fontFamily: 'Lato',
                  borderWidth: 1,
                }}
                placeholder="Text"
              />
            </div>

            <div style={{float: 'right'}}>
              {
                overCharLimit ? 
                  <span><span style={{color: 'red'}}>{value?.length || 0}</span>/{characterLimit}</span> :
                  <span>{value?.length || 0}/{characterLimit}</span> 
              }
            </div>
          </div>
        );
      case FormFieldType.FILE:
        return <FileUpload id={name} label={label} {...filteredField} {...filteredProps} />;

      case FormFieldType.HIDDEN:
        return <input id={name} {...filteredField} {...filteredProps} />;

      default: {
        let placeholderText = 'Text';

        if (type === FormFieldType.EMAIL) {
          placeholderText = 'e.g. exampleuser@email.com';
        }

        if (label?.toString().includes('Phone')) {
          placeholderText = '123-333-3333';
        }

        return (
          <>
            <label htmlFor={name} className="sui-label">
              {generateLabel()}
            </label>

            <input
              autoComplete="off"
              data-testid={testId}
              className={`${classes} sui-h-6 sui-px-1 sui-py-1 sui-body`}
              id={name}
              {...filteredField}
              {...filteredProps}
              placeholder={placeholderText}
            />
         </>
        );
      }
    }
  };

  return (
    <FieldGroup
      mods={`u-sizeFull ${mods || ''} ${isMultiSelect ? 'sui-px-1 sui-mb-1' : 'sui-px-1'} ${
        type !== FormFieldType.SELECT ? 'sui-mb-2 ' : ''
      }`}
    >
      {renderType()}

      {hasError && type !== FormFieldType.SELECT ? (
        <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
          {error}
        </FieldMessage>
      ) : null}
    </FieldGroup>
  );
};
