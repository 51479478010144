import { NotFound } from 'pages/NotFound';
import { SignWaivers } from 'components/Form/SignWaivers/SignWaivers';
import * as React from 'react';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import { useFormSelector } from 'state/form/formSlice';
import { useRegistrationSelector, loadWaiverResults } from 'state/registration/registrationSlice';
import { FormNgbIntegration, NgbIntegrationsService, Registration, RegistrationUpdate } from 'core/api';
import { NGB_INTEGRATION_CODES } from 'core/constants';
import { ActionContainer, CartButton } from 'components/shared';
import { Header } from 'components/Form';
import { Button } from '@teamsnap/teamsnap-ui';
import { useUserSelector } from 'state/user/userSlice';
import { Banner } from '@teamsnap/snap-ui';

export const WaiverPage = () => {
  const form = useFormSelector();
  const dispatch = useAppDispatch();
  const registrationData = useRegistrationSelector();
  const [waivers, setWaivers] = React.useState(form?.waivers ?? []);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const registrationsForForm = registrationData?.registrations?.filter(
      (reg: Registration) => reg.formId === form?.id && reg.status === RegistrationUpdate.status.IN_CART
    );
    registrationsForForm?.forEach((r: Registration) => {
      dispatch(loadWaiverResults(r.id));
    });
  }, [registrationData?.registrations]);


  React.useEffect(() => {
    const getNgbWaivers = async ({ ngbIntegrationId, seasonStartDate, seasonEndDate }: FormNgbIntegration) => {
      if (!form || !seasonStartDate || !seasonEndDate) {
        return
      }

      try {
        const response = await NgbIntegrationsService.getNgbWaivers(
          ngbIntegrationId,
          form.organizationId,
          form.id,
          seasonStartDate,
          seasonEndDate
        )

        setWaivers(response.formWaivers);
      }
      catch (e) {
        setError('The Hockey Canada API is preventing waivers from loading. These waivers are required to complete registration, please try again later.');
      }
      finally {
        setIsLoading(false);
      }
    }

    const hockeyCanadaIntegration = form?.ngbIntegrations?.find(
      (integration) => integration.ngbIntegration.code === NGB_INTEGRATION_CODES.hockey_canada
    );

    if (hockeyCanadaIntegration) {
      getNgbWaivers(hockeyCanadaIntegration);
    } else {
      setIsLoading(false);
    }
  }, []);

  if (!form) {
    return null;
  }

  if (waivers.length === 0) {
    if (form) {
      window.location.href = `/form/${form.id}/cart`;
    } else {
      return <NotFound />;
    }
  }

  if (error) {
    return (
      <DisabledContainer>
        <Banner
          caption={error}
          sentiment='negative'
        />
      </DisabledContainer>
    )
  }

  if (isLoading) {
    return (
      <DisabledContainer>
        <div className="Loader-indicator">
          <span className="SpinAnimation" />
          <div className="Loader-indicatorText">
            Loading...
          </div>
        </div>
      </DisabledContainer>
    )
  }

  return (
    <SignWaivers registrationWaivers={registrationData?.waiverResults} formWaivers={waivers} registrationForm={form} />
  );
};

function DisabledContainer({ children }: { children: React.ReactNode }) {
  const navigate = useAppNavigate();
  const user = useUserSelector();
  const form = useFormSelector();

  return (
    <ActionContainer
      submitting={false}
      header={
        <Header
          title="Checkout - Agreements"
          navigation={
            <Button
              iconPosition="left"
              mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
              icon="arrow-left"
              type="link"
              size="large"
              onClick={() => {
                navigate(`/form/${form?.id}/cart`);
              }}
            />
          }
          rightIcon={<CartButton />}
          profileName={`${user?.firstName} ${user?.lastName}`}
          testId="waiver-page-header"
        />
      }
      footer={
        <div className="t:sui-flex t:items-center t:sui-justify-between">
          <div>
            <Button
              mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
              onClick={() => {
                navigate(`/form/${form?.id}/cart`);
              }}
              icon="arrow-left"
              iconPosition="left"
            >
              Back
            </Button>
          </div>

          <Button
            key="check-out"
            color="primary"
            mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
            icon="arrow-right"
            iconPosition="right"
            isDisabled
          >
            Next
          </Button>
        </div>
      }
      children={children}
    />
  )
}
