import * as React from 'react';
import { Button, Loader } from '@teamsnap/teamsnap-ui';
import { Form, Formik, FormikErrors } from 'formik';

import { ActionContainer } from 'components/shared';
import { FieldType } from '../FormField/FormField';
import { Header } from '../Header/Header';
import { useUserSelector } from 'state/user/userSlice';
import { useAppDispatch, useAppNavigate } from 'state/hooks';
import {
  saveFieldAdjustmentResults,
  saveFieldResults,
  updateRegistrationStatus,
  useCurrentFieldAdjustmentResults,
  useCurrentFieldResults,
  useCurrentGroupSelector,
  useCurrentMemberSelector,
  useCurrentMemberVerified,
  useCurrentRegistration,
} from 'state/registration/registrationSlice';
import { useFormSelector } from 'state/form/formSlice';
import { RegistrantInfo } from '../RegistrantInfo/RegistrantInfo';
import { Accordion } from '../../shared';
import {
  AdjustmentResults,
  FieldResults,
  FormField as FormFieldModel,
  HouseholdPerson,
  Person,
  RegistrationUpdate,
} from 'core/api';
import { GuardianSelect } from '../GuardianSelect/GuardianSelect';
import { CartButton } from 'components/shared/CartButton';
import { useHouseholdPeopleSelector } from 'state/households/householdSlice';
import { splitAdjustmentValues } from '../AdjustmentValue/AdjustmentHelpers';
import { Question } from './Question';
import {
  ValidHockeyCanadaMemberFields,
  ValidUsaHockeyMemberFields,
  ValidUsaLacrosseMemberFields,
} from 'state/registration/types';
import * as Sentry from '@sentry/react';
import { NGB_INTEGRATION_CODES } from 'core/constants';
import hockeyCanadaLogoImage from 'assets/images/hockey-canada-logo.svg';
import { NgbFieldData } from 'pages/Form/VerifyMembershipPage';

enum FormSections {
  PARTICIPANT = 'participant',
  GUARDIAN = 'guardian',
  ADDITIONAL = 'additional',
  NGB = 'ngb',
}

interface FormFieldsRow {
  [key: string]: FormFieldModel[];
}

interface FieldsSortedBySection {
  [key: string]: FormFieldModel[];
}

interface FieldsSortedByRows {
  [key: string]: FormFieldsRow;
}

const getFieldValue = (field: FormFieldModel, person: Person) => {
  if (field.formFieldDefinition.name === FieldType.FIRST_NAME) {
    return (person?.firstName as string) || '';
  } else if (field.formFieldDefinition.name === FieldType.LAST_NAME) {
    return (person?.lastName as string) || '';
  } else if (field.formFieldDefinition.name === FieldType.BIRTHDATE) {
    return (person?.birthdate as string) || '';
  } else if (field.formFieldDefinition.name === FieldType.EMAIL) {
    return (person?.email?.emailAddress as string) || '';
  } else if (field.formFieldDefinition.name === FieldType.GENDER) {
    let gender = person?.gender as string;
    gender = gender?.charAt(0).toUpperCase() + gender?.slice(1);

    return gender || '';
  } else if (field.formFieldDefinition.name === FieldType.PHONE_NUMBER) {
    return (person?.phone?.phoneNumber as string) || '';
  }

  return field.multiselect ? [] : '';
};

// return true if field is not internal and not a ngb field, if a NGB field, it should only return true if they are editable
// this will not set the value in initialValues so it will error if is required
const isFieldNotInternalAndIsEditableNgbField = (field: FormFieldModel, editableNgbFields: NgbFieldData[]) =>
  !field.internal && (
    !(field.formFieldDefinition.externalId || field.formFieldDefinition.externalType) ||
    editableNgbFields.map((f) => f.name).includes(field.formFieldDefinition.name)
  )

const multiselectValueDelimiter = '|';

export const AnswerQuestions = () => {
  const { useState } = React;

  const [isGuardianSelectOpen, setIsGuardianSelectOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const navigate = useAppNavigate();
  const user = useUserSelector();
  const member = useCurrentMemberSelector();
  const group = useCurrentGroupSelector();
  const households = useHouseholdPeopleSelector();
  const currentRegistration = useCurrentRegistration();
  const currentFieldResults = useCurrentFieldResults();
  const currentFieldAdjustmentResults = useCurrentFieldAdjustmentResults();
  const currentMemberVerified = useCurrentMemberVerified();
  const dispatch = useAppDispatch();

  const form = useFormSelector();
  const { organizationName, ngbIntegrations } = form || {};
  // only support one ngb integration per form for now
  const selectedNgbIntegration = ngbIntegrations ? ngbIntegrations[0] : null;
  const selectedNgbIntegrationId = selectedNgbIntegration?.ngbIntegrationId;
  const selectedNgbIntegrationCode = selectedNgbIntegration?.ngbIntegration?.code;
  const ngbFields: NgbFieldData[] = selectedNgbIntegration?.ngbIntegration?.fields || [];
  const editableNgbFields = ngbFields?.filter((f) => f.registrant_can_view) || [];

  const fields = form?.fields.filter(
    (field) => field.enabled || field.internal
  ) as FormFieldModel[];

  const formSectionsHeadingName = new Map();
  formSectionsHeadingName.set(
    FormSections.PARTICIPANT,
    'Participant Information'
  );
  formSectionsHeadingName.set(FormSections.GUARDIAN, 'Guardian Information');
  formSectionsHeadingName.set(FormSections.ADDITIONAL, 'Additional Questions');
  formSectionsHeadingName.set(FormSections.NGB, selectedNgbIntegrationCode === NGB_INTEGRATION_CODES.hockey_canada ? 'HCR Questions' : 'NGB Questions');

  const ngbFormFields = form?.fields.filter(
    (field) =>
      field.formFieldDefinition.externalId === selectedNgbIntegrationId &&
      field.formFieldDefinition.externalType === 'ngb_integration'
  ) as FormFieldModel[];

  const isValidMemberKey = (
    key: string,
    currentMemberVerified:
      | ValidUsaLacrosseMemberFields
      | ValidUsaHockeyMemberFields
      | ValidHockeyCanadaMemberFields
  ): key is keyof (
    | ValidUsaLacrosseMemberFields
    | ValidUsaHockeyMemberFields
    | ValidHockeyCanadaMemberFields
  ) => {
    return key in currentMemberVerified;
  };

  React.useEffect(() => {
    if (currentMemberVerified && currentRegistration && ngbFormFields.length > 0) {
      const fieldResults: FieldResults['fields'] = [];

      ngbFormFields.forEach((ngbField) => {
        const fieldName = ngbField.formFieldDefinition.name;
        if (isValidMemberKey(fieldName, currentMemberVerified)) {
          fieldResults.push({
            formFieldId: ngbField.id,
            value: currentMemberVerified[fieldName],
          });
        }
      });

      if (fieldResults.length > 0) {
        dispatch(
          saveFieldResults({
            registrationId: currentRegistration?.id,
            body: { fields: fieldResults },
          })
        ).catch((e) => {
          Sentry.captureException(e, {
            extra: {
              fieldstoSave: fieldResults,
              currentMemberVerified,
              currentRegistration,
              ngbFormFields,
            },
          });
        });
      }
    }
  }, [currentMemberVerified, currentRegistration]);

  const buildFormObject = (fields: FormFieldModel[]) => {
    const FieldsSortedBySection: FieldsSortedBySection = {};
    const initialValues: { [key: string]: string | string[] } = {};
    let fieldMapping: {
      [key: number]: { type: string; multiselect: boolean };
    } = {};

    // Separate into different sections
    for (const field of fields) {
      fieldMapping = {
        ...fieldMapping,
        [field.id]: {
          type: field.type,
          multiselect: field.multiselect,
        },
      };

      const { target, formFieldDefinition } = field;
      const { organizationId, externalId, externalType } = formFieldDefinition;

      const currTarget =
        target === 'participant' && organizationId ? 'additional' : (target === 'participant' && externalId && externalType ? 'ngb' : target);

      if (FieldsSortedBySection[currTarget]) {
        FieldsSortedBySection[currTarget].push(field);
      } else {
        FieldsSortedBySection[currTarget] = [field];
      }

      if (field.target === FormSections.PARTICIPANT) {
        if (isFieldNotInternalAndIsEditableNgbField(field, editableNgbFields)) {
          initialValues[field.id] = getFieldValue(
            field,
            member?.person as Person
          );
        } else if (field.formFieldDefinition.name === 'participation_group') {
          initialValues[field.id] = group?.name || '';
        }
      } else if (
        field.target === FormSections.GUARDIAN &&
        user?.id !== member?.person?.userId
      ) {
        const guardian = households?.find(
          (householdPerson) => householdPerson.person.uuid === user?.personUuid
        );
        initialValues[field.id] = getFieldValue(
          field,
          guardian?.person as Person
        );
      } else if (field.multiselect) {
        initialValues[field.id] = [];
      } else {
        initialValues[field.id] = '';
      }
    }

    currentFieldResults?.forEach((fieldResult) => {
      if (fieldMapping[fieldResult.formFieldId]?.multiselect) {
        initialValues[fieldResult.formFieldId] = fieldResult.value.split(
          multiselectValueDelimiter
        );
      } else {
        initialValues[fieldResult.formFieldId] = fieldResult.value;
      }
    });

    currentFieldAdjustmentResults?.forEach((adjustmentResult) => {
      const type = adjustmentResult.amount === 0 ? 'quantity' : 'amount';
      initialValues[adjustmentResult.formAdjustmentId + '-' + type] =
        adjustmentResult[type].toString();
    });

    // Separate into rows.
    const fieldsSortedByRows: FieldsSortedByRows = {};
    for (const section in FieldsSortedBySection) {
      FieldsSortedBySection[section].sort(
        (a: FormFieldModel, b: FormFieldModel) => a.row - b.row
      );
      fieldsSortedByRows[section] = FieldsSortedBySection[section].reduce(
        (r: FormFieldsRow, a: FormFieldModel) => {
          r[a.row.toString()] = [...(r[a.row.toString()] || []), a];
          return r;
        },
        {}
      );
    }

    return {
      formFields: {
        participant: fieldsSortedByRows[FormSections.PARTICIPANT],
        ...fieldsSortedByRows,
      } as FieldsSortedByRows, // Make sure participant is first
      initialValues,
      fieldMapping,
    };
  };

  const formObject = buildFormObject(fields);

  const isPhoneType = (fieldId: number) =>
    formObject['fieldMapping'][fieldId]?.type === 'phone';

  const hasAllInternalFields = (fields: FormFieldsRow) =>
    Object.values(fields).every((field: FormFieldModel[]) =>
      field.every(
        (item: FormFieldModel) => item.internal
      )
    );

  const scrollToFirstError = (
    errors: FormikErrors<{ [key: string]: string | string[] }>
  ) => {
    // Find the first field with an error and scroll to it
    const firstErrorFieldId = Object.keys(errors)[0];
    if (firstErrorFieldId) {
      const errorFieldElement = document.getElementById(firstErrorFieldId);
      if (errorFieldElement) {
        errorFieldElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  };

  return (
    <div data-testid="AnswerQuestions">
      <Formik
        initialValues={formObject.initialValues}
        onSubmit={async (values) => {
          if (currentRegistration) {
            setIsSubmitting(true);
            const fields: FieldResults['fields'] = [];
            const adjustments: AdjustmentResults['adjustmentResults'] = [];

            const [fieldValues, adjustmentValues] =
              splitAdjustmentValues(values);

            for (const [id, item] of Object.entries(fieldValues)) {
              // If this is a phone field we need to strip special characters
              fields.push({
                formFieldId: +id,
                value: Array.isArray(item)
                  ? item.join(multiselectValueDelimiter)
                  : isPhoneType(+id)
                    ? item.replace(/[^0-9]/g, '')
                    : item.toString(), // form field result values should be stored in string
              });
            }

            for (const [key, item] of Object.entries(adjustmentValues)) {
              const [id, type] = key.split('-');
              const itemValue =
                item !== null && item !== '' ? parseInt(item as string) : 0;

              adjustments.push({
                formAdjustmentId: +id,
                amount: type === 'amount' ? itemValue : 0,
                quantity: type === 'quantity' ? itemValue : 0,
              });
            }

            const response: any = await dispatch(
              updateRegistrationStatus({
                registrationId: currentRegistration?.id,
                body: { status: RegistrationUpdate.status.IN_CART },
              })
            );

            // if status update to in_cart succeeds then we can save the form field results and form field adjustment results,
            // if it fails then it will show the modal error from ErrorHandler and the redirect to cart page will not happen.
            if (!response.payload.error) {
              await dispatch(
                saveFieldResults({
                  registrationId: currentRegistration?.id,
                  body: { fields },
                })
              )
                ?.then(
                  async () =>
                    await dispatch(
                      saveFieldAdjustmentResults({
                        registrationId: currentRegistration?.id,
                        body: adjustments,
                      })
                    )
                )
                ?.then(() => {
                  setIsSubmitting(false);
                });

              navigate(`/form/${form?.id}/cart`);
            }
          }
        }}
      >
        {({ handleSubmit, errors, setFieldValue }) => {
          return (
            <ActionContainer
              title="Answer Questions"
              submitting={false}
              removeContentFormatting={true}
              footer={
                <div className="t:sui-flex t:items-center t:sui-justify-between">
                  <Button
                    key="add-another-member"
                    mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1 sui-hidden t:sui-flex"
                    icon="arrow-left"
                    iconPosition="left"
                    isDisabled={isSubmitting}
                    onClick={() => navigate(`/form/${form?.id}/selectGroup`)}
                  >
                    Back
                  </Button>
                  <Button
                    color="primary"
                    mods="sui-w-full sui-my-1 t:sui-w-auto sui-px-3 sui-py-1 sui-h-auto sui-leading-1"
                    icon={isSubmitting ? '' : 'arrow-right'}
                    iconPosition="right"
                    isDisabled={isSubmitting}
                    onClick={() => {
                      scrollToFirstError(errors);
                      handleSubmit();
                    }}
                  >
                    {isSubmitting ? <Loader type="spin" /> : 'Add to Cart'}
                  </Button>
                </div>
              }
              header={
                <>
                  <Header
                    title={organizationName ?? 'Answer Questions'}
                    navigation={
                      <Button
                        iconPosition="left"
                        mods="back-button sui-m-0 sui-w-auto sui-text-gray-10 t:sui-hidden"
                        icon="arrow-left"
                        type="link"
                        size="large"
                        onClick={() =>
                          navigate(`/form/${form?.id}/selectGroup`)
                        }
                      />
                    }
                    rightIcon={<CartButton />}
                    profileName={`${user?.firstName} ${user?.lastName}`}
                  />
                  {member?.person && group && (
                    <RegistrantInfo registrant={member.person} group={group} />
                  )}
                </>
              }
            >
              <div className="sui-pb-4">
                {isGuardianSelectOpen && (
                  <GuardianSelect
                    onClose={() => setIsGuardianSelectOpen(false)}
                    onGuardianSelect={(householdPerson: HouseholdPerson) => {
                      const guardianFields = formObject.formFields.guardian;
                      for (const fieldRowKey in guardianFields) {
                        for (const field of guardianFields[fieldRowKey]) {
                          setFieldValue(
                            String(field.id),
                            getFieldValue(
                              field,
                              householdPerson?.person as Person
                            )
                          );
                        }
                      }
                    }}
                  />
                )}
                <Form>
                  {Object.values(FormSections)?.map((section: string) => {
                    const formSection: FormFieldsRow =
                      formObject.formFields[section] || {};

                    if (hasAllInternalFields(formSection)) {
                      return null;
                    }

                    return (
                      <div data-testid={section} key={section}>
                        <Accordion
                          title={formSectionsHeadingName.get(section)}
                          openOverride={Object.keys(errors).length > 0}
                          logo={section === 'ngb' && selectedNgbIntegrationCode === NGB_INTEGRATION_CODES.hockey_canada ? <img className="sui-w-1/8 u-padLeftMd" src={hockeyCanadaLogoImage} alt="HCR" /> : undefined}
                        >
                          <>
                            {section === FormSections.GUARDIAN && (
                              <Button
                                type="link"
                                mods="sui-label sui-leading-none sui-h-auto sui-mx-1 sui-mt-3 sui-mb-1"
                                onClick={() => setIsGuardianSelectOpen(true)}
                              >
                                Select a household member
                              </Button>
                            )}
                            <div className="sui-mt-2">
                              {Object.keys(formSection)?.map(
                                (rowKey: string) => {
                                  const fieldRows: FormFieldModel[] =
                                    formSection[+rowKey];

                                  return (
                                    <React.Fragment
                                      key={`${section}-${rowKey}`}
                                    >
                                      {fieldRows?.map(
                                        (field: FormFieldModel) => (
                                          <div
                                            data-testid={`${field.label}`}
                                            key={field.id}
                                            className="sui-px-1 sui-flex-1"
                                          >
                                            <Question
                                              data-testid={`${field.label}-${field.type}`}
                                              field={field}
                                              editableNgbFields={editableNgbFields}
                                            />
                                          </div>
                                        )
                                      )}
                                    </React.Fragment>
                                  );
                                }
                              )}
                            </div>
                          </>
                        </Accordion>
                      </div>
                    );
                  })}
                </Form>
              </div>
            </ActionContainer>
          );
        }}
      </Formik>
    </div>
  );
};
