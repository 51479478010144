/* eslint-disable @typescript-eslint/ban-ts-comment */
import { OpenAPI } from 'core/api';
import { API_URL, SENTRY_URL } from 'core/constants';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ENVIRONMENT from './core/environment';
import { ampli } from 'core/ampli';

// Local Imports
import { ErrorHandler } from 'components/Form/ErrorHandler/ErrorHandler';
import { Root } from './pages/root';
import { store } from './state/store';
import { NotFound } from 'pages/NotFound';
import '@teamsnap/snap-ui/build/snap-ui.min.css';
import '@teamsnap/teamsnap-ui/src/css/teamsnap-ui.scss';
import './core/css/root.scss';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

OpenAPI.BASE = API_URL;
OpenAPI.WITH_CREDENTIALS = true;

const buildId = process.env.REACT_APP_RELEASE || '';

window.onload = async () => {
  // @ts-ignore
  if (ENVIRONMENT === 'local' || window?.location?.host?.includes('localhost')) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
    release: `registration-frontend@${buildId}`,
    ignoreErrors: [
      // We are ignoring ResizeObserver loop limit exceeded errors because they are not actionable and are not causing any issues.
      // They stem from use of a 3rd-party widget.
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
  });

  ampli.load({
    // @ts-ignore
    environment: ENVIRONMENT === 'prod'
        ? 'prodteamsnapforbusiness'
        : 'devteamsnapforbusiness',
    client: { configuration: { defaultTracking: false } },
  });
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('error_boundary', 'index');
        }}
        fallback={<ErrorMessage message="Oops, something went wrong!" />}
      >
        <ErrorHandler />
        <BrowserRouter>
          <Routes>
            <Route path="/form/:formId" element={<Root />} />
            <Route path="/form/:formId/:path" element={<Root />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
